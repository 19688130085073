<template>
	<div class="pupup_wrap">
		<div class="container">
			<div class="row">
				<div class="main_card_wrap mt-20">

					<div class="main_card main_card_type4">
						<div
							class="cover atvImg"
						>
							<div
								class="atvImg-layer"
								:data-img="item.nft_card_img_url"
							></div>
							<!-- <div class="atvImg-layer" data-img="/assets/image/union_madam.png"></div> -->
							<!-- <div class="atvImg-layer" data-img="/assets/image/union_accountant.png"></div> -->
							<!-- <div class="atvImg-layer" data-img="/assets/image/union_consigliere.png"></div> -->
						</div>
					</div>
				</div>
				<div class="card_union mt-20">
					<strong class="name">{{  card_type_code[item.nft_card_grade_code] }}</strong>
					<span class="type mt-10">{{  item.nft_card_desctn }}</span>
				</div>

				<div class="mt-30 bg-history pa-10 radius-20">
					<h3 class="color-red size-px-16 pa-10 under-line">{{ $language.staking.txt_staking_history }}</h3>
					<div class="color-white size-px-14 flex-row justify-space-between pa-10 under-line">
						<span>{{ $language.staking.txt_staking_history_date }}</span>
						<span>{{ $language.staking.txt_staking_history_quantity }}</span>
					</div>
					<ul
						v-if="items.length > 0"
						style="max-height: 240px; overflow: auto"
					>
						<li
							v-for="(history, h_index) in items"
							:key="'history_' + h_index"
							class="pa-10 color-white flex-row justify-space-between size-px-14"
						>
							<span>{{ history.reward_daytime}}</span>
							<span>{{ history.reward_token_quantity | makeComma(8)}}</span>
						</li>
					</ul>
					<div
						v-else
						class="pa-50 color-white text-center size-px-14"
					>{{ $language.staking.txt_staking_history_date }}</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'mafia0732'
	, props: ['user']
	, components: {}
	, data: function(){

		return {
			program: {
				name: this.$language.common.union_card
				, type: 'popup'
				, title: 'STAKING HISTORY'
				, from: 'staking'
			}
			, item: {

			}
			, items: []
			, item_search: {
				page: 1
				, list_cnt: 10
			}
			, card_type_code: {
				'NF00200041': 'CONSIGLIERE'
				, 'NF00200042': 'ACCOUNTANT'
				, 'NF00200043': 'MADAM'
				, 'NF00200044': 'SPY'
			}
		}
	}
	,computed: {
	}
	, methods: {
		getData: async function(){
			try{

				const result = await this.$Request({
					method: 'post'
					,url: this.$api_url.api_path.get_staking_history_info
					,data: {
						member_number: this.user.member_number
						, nft_card_number: this.$route.params.id
						, page_number: this.item_search.page
						, pagerecnum: this.item_search.list_cnt
					}
					, type: true
				})

				if(result.success){
					this.item = result.data
					if(this.items.length > 0){
						this.items = this.items.concat(result.data.stakg_nft_card_reward_list)
					}else{
						this.items = result.data.stakg_nft_card_reward_list

						if(this.item.nft_card_img_url){
							setTimeout(() => {
								window.atvImg()
							}, 100)
						}
					}

					if(result.data.stakg_nft_card_reward_list.length > 0){
						this.item_search.page++
						setTimeout( async () => {
							await this.getData()
						}, 100)

					}
				}else{
					this.$bus.$emit('notify',  { type: 'error', message: result.message, config: { bottom: 0}})
				}
			}catch(e){
				this.$bus.$emit('notify',  { type: 'error', message: this.$language.common.error, config: { bottom: 0}})
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	, created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>

<style>
.card_info { float: none; text-align: right;}

.bg-history { background-color: var(--bg-Blue02)}
.color-red {color: var(--red)}
</style>